{
  "name": "@vercel/analytics",
  "version": "1.0.2",
  "description": "Gain real-time traffic insights with Vercel Web Analytics",
  "keywords": [
    "analytics",
    "vercel"
  ],
  "repository": {
    "url": "github:vercel/analytics",
    "directory": "packages/web"
  },
  "license": "MPL-2.0",
  "type": "module",
  "exports": {
    "./package.json": "./package.json",
    ".": {
      "browser": "./dist/index.js",
      "import": "./dist/index.js",
      "require": "./dist/index.cjs"
    },
    "./react": {
      "browser": "./dist/react/index.js",
      "import": "./dist/react/index.js",
      "require": "./dist/react/index.cjs"
    }
  },
  "main": "dist/index.js",
  "types": "dist/index.d.ts",
  "typesVersions": {
    "*": {
      "*": [
        "dist/index.d.ts"
      ],
      "react": [
        "dist/react/index.d.ts"
      ]
    }
  },
  "scripts": {
    "build": "tsup",
    "dev": "tsup --watch",
    "lint": "eslint .",
    "lint-fix": "eslint . --fix",
    "test": "jest",
    "type-check": "tsc --noEmit"
  },
  "eslintConfig": {
    "extends": [
      "@vercel/eslint-config"
    ],
    "rules": {
      "tsdoc/syntax": "off"
    },
    "ignorePatterns": [
      "jest.setup.ts"
    ]
  },
  "devDependencies": {
    "@swc/core": "^1.3.66",
    "@swc/jest": "^0.2.26",
    "@testing-library/jest-dom": "^5.16.5",
    "@testing-library/react": "^14.0.0",
    "@types/jest": "^29.5.2",
    "@types/node": "^20.3.1",
    "@types/react": "^18.2.14",
    "@types/testing-library__jest-dom": "^5.14.6",
    "@vercel/eslint-config": "workspace:0.0.0",
    "jest": "^29.5.0",
    "jest-environment-jsdom": "^29.5.0",
    "react": "^18.2.0",
    "react-dom": "^18.2.0",
    "tsup": "7.1.0"
  }
}
